import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
const app = createApp(App);
// 基于vue2
// import VueHtml5Editor from "vue-html5-editor";
// import "font-awesome/css/font-awesome.min.css";

// let opt = {
//   // 全局组件名称，使用new VueHtml5Editor(options)时该选项无效
//   name: "vue-html5-editor",
//   // 是否显示模块名称，开启的话会在工具栏的图标后台直接显示名称
//   showModuleName: true,
//   // 自定义各个图标的class，默认使用的是font-awesome提供的图标
//   icons: {
//     text: "fa fa-pencil",
//     color: "fa fa-paint-brush",
//     font: "fa fa-font",
//     align: "fa fa-align-justify",
//     list: "fa fa-list",
//     link: "fa fa-chain",
//     unlink: "fa fa-chain-broken",
//     tabulation: "fa fa-table",
//     image: "fa fa-file-image-o",
//     hr: "fa fa-minus",
//     eraser: "fa fa-eraser",
//     undo: "fa-undo fa",
//     "full-screen": "fa fa-arrows-alt",
//     info: "fa fa-info",
//   },
//   // 配置图片模块
//   image: {
//     // 文件最大体积，单位字节
//     sizeLimit: 512 * 1024 * 10,
//     // 上传参数,默认把图片转为base64而不上传
//     // upload config,default null and convert image to base64
//     upload: {
//       url: null,
//       headers: {},
//       params: {},
//       fieldName: {},
//     },
//     // 压缩参数,默认使用localResizeIMG进行压缩,设置为null禁止压缩
//     // width和height是文件的最大宽高
//     compress: {
//       width: 600,
//       height: 600,
//       quality: 80,
//     },
//     // 响应数据处理,最终返回图片链接
//     uploadHandler(responseText) {
//       //      default accept json data like  {ok:false,msg:"unexpected"} or {ok:true,data:"image url"}
//       var json = JSON.parse(responseText);
//       if (json.status == 200) {
//         return json.data;
//       } else {
//         alert(json.error);
//       }
//     },
//   },
//   // 语言，内建的有英文（en-us）和中文（zh-cn）
//   language: "zh-cn",
//   // 自定义语言
//   i18n: {
//     "zh-cn": {
//       align: "对齐方式",
//       image: "图片",
//       list: "列表",
//       link: "链接",
//       unlink: "去除链接",
//       table: "表格",
//       font: "文字",
//       "full screen": "全屏",
//       text: "排版",
//       eraser: "格式清除",
//       info: "关于",
//       color: "颜色",
//       "please enter a url": "请输入地址",
//       "create link": "创建链接",
//       bold: "加粗",
//       italic: "倾斜",
//       underline: "下划线",
//       "strike through": "删除线",
//       subscript: "上标",
//       superscript: "下标",
//       heading: "标题",
//       "font name": "字体",
//       "font size": "文字大小",
//       "left justify": "左对齐",
//       "center justify": "居中",
//       "right justify": "右对齐",
//       "ordered list": "有序列表",
//       "unordered list": "无序列表",
//       "fore color": "前景色",
//       "background color": "背景色",
//       "row count": "行数",
//       "column count": "列数",
//       save: "确定",
//       upload: "上传",
//       progress: "进度",
//       unknown: "未知",
//       "please wait": "请稍等",
//       error: "错误",
//       abort: "中断",
//       reset: "重置",
//     },
//   },
//   // 隐藏不想要显示出来的模块
//   hiddenModules: [],
//   // 自定义要显示的模块，并控制顺序
//   visibleModules: [
//     //    "text",
//     //    "color",
//     "font",
//     "align",
//     //    "list",
//     //    "link",
//     //    "unlink",
//     //    "tabulation",
//     "image",
//     //    "hr",
//     //    "eraser",
//     "undo",
//     //    "full-screen",
//     //    "info",
//   ],
//   // 扩展模块，具体可以参考examples或查看源码
//   // extended modules
//   modules: {
//     //omit,reference to source code of build-in modules
//   },
// };
// app.use(VueHtml5Editor, opt);

/**
 * 有赞 mobile ui begin
 */
// 1. 引入你需要的组件
// import { Button, Col, Row } from "vant";
// import { showDialog, showNotify } from "vant";

// // 2. 引入组件样式
// import "vant/lib/index.css";

// // 3. 注册你需要的组件
// app.use(Button);
// app.use(Col);
// app.use(Row);

// //京东mobile uibegin
// import NutUI from "@nutui/nutui";
// import "@nutui/nutui/dist/style.css";
// //京东mobile uiend
// app.use(NutUI);
/**
 * 有赞 mobile ui end
 */

// import TDesign from "tdesign-mobile-vue";
// import { isObject } from "@vue/shared";
// app.use(isObject);
// app.use(TDesign);

// /**
//  * ant 阿里mobile插件begin
//  */
// import Antd from "ant-design-vue";
// import "ant-design-vue/dist/reset.css";
// app.use(Antd);
/**
 * ant 阿里mobile插件end
 */
import lodash from "lodash";
window._ = lodash;

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
app.use(ElementPlus);
import { ElMessage, ElMessageBox, ElNotification } from "element-plus";

import * as mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
app.use(mavonEditor);

import * as ElementPlusIconsVue from "@element-plus/icons-vue";
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

import Antd from "ant-design-vue";
import "ant-design-vue/dist/reset.css";
app.use(Antd);

import qs from "qs";
app.config.globalProperties.$qs = qs;
import axios from "axios";
app.config.globalProperties.$axios = axios;
//axios.defaults.baseURL = "http://localhost:8208";
import NProgress from "nprogress"; // 引入nprogress插件
import "nprogress/nprogress.css"; // 这个nprogress样式必须引入
NProgress.configure({
  ease: "ease",
  speed: 500,
});

// axios.defaults.baseURL = "localhost:7812"; //https://daimane.com/freeweb 动态设置
axios.interceptors.request.use(
  (config) => {
    NProgress.start(); // 设置加载进度条(开始..)

    // 在发送请求之前做些什么
    //判断是否存在token，如果存在将每个页面header都添加token
    //alert(axios.defaults.baseURL)
    if (sessionStorage.getItem("newFreeAccessToken")) {
      // config.headers.common["Authorization"] =
      //   sessionStorage.getItem("accessToken");
      config.headers["Free-Authorization"] =
        sessionStorage.getItem("newFreeAccessToken");
    }
    // 拦截失效、非法请求
    // else{
    //   config.cancelToken = new cancelToken((c) => {
    //     this.errorMsgBox("登录失效，请重新登录")
    //   });
    // }

    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    NProgress.done(); // 设置加载进度条(结束..)
    return response;
  },
  (error) => {
    alert(error);

    if (error.response) {
      switch (error.response.status) {
        case 401:
          this.$store.commit("del_token");
          router.replace({
            path: "/login",
            query: {
              redirect: router.currentRoute.fullPath,
            }, //登录成功后跳入浏览的当前页面
          });
          break;
        case 500:
          Message;
      }
      return Promise.reject(error.response.data);
    } else {
      //TODO:网络超时反应，返回指定提示内容，使用时，需要根据
      return window.const.net_connected_error;
    }
  }
);
//组件复用
app.mixin({
  setup() {},
  methods: {
    // errorMsgBox(notice) {
    // 	this.$message({
    // 		message: notice,
    // 		type: "error",
    // 	});
    // },
    // successMsgBox(notice) {
    // 	if (!notice) {
    // 		notice = "成功";
    // 	}
    // 	this.$message({
    // 		message: notice,
    // 		type: "info",
    // 	});
    // },
    errorMsgBox(notice) {
      // this.$message({
      //   message: notice,
      //   type: "error",
      // });

      ElNotification.error({
        title: "错误",
        message: notice,
        position: "bottom-right",
      });
    },
    successMsgBox(notice) {
      if (!notice) {
        notice = "成功";
      }
      // this.$message({
      //   message: notice,
      //   type: "success",
      // });

      ElNotification({
        title: "提示",
        message: notice,
        position: "bottom-right",
      });
    },

    confirm(notice, callback) {
      // 显示消息提示框，并等待用户确认
      ElMessageBox.confirm(notice, "操作确认")
        .then(() => {
          callback();
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    },

    vantDialog(msg) {
      showDialog({
        type: "danger",
        message: msg,
      });
    },
    vantNotify(msg) {
      showNotify({
        position: "bottom",
        type: "danger",
        message: msg,
      });
    },
    vantSuccessNotify(msg) {
      showNotify({
        position: "bottom",
        type: "success",
        message: msg,
      });
    },

    //form表单提交，参数不用转换，自动修改头
    formDataApi(data, api) {
      axios.defaults.baseURL =
        window.location.origin == "http://localhost:8070"
          ? "api"
          : "https://daimane.com/freeweb/";
      return async () => {
        let res = null;
        try {
          res = await axios.post(api, data);
          res = res.data;
          return res;
        } catch (e) {
          this.$message({
            message: e.message,
            type: "error",
          });
          if (_.isFunction(error)) {
            await error(e);
          }
          return defaultValue;
        }
      };
    },
    formDataApi2(data, api) {
      axios.defaults.baseURL =
        window.location.origin == "http://localhost:8070"
          ? "http://localhost:7812/wgnweb/"
          : "https://daimane.com/wgnoweb/";
      return async () => {
        let res = null;
        try {
          res = await axios.post(api, data);
          res = res.data;
          return res;
        } catch (e) {
          this.errorMsgBox(e.message);
          if (_.isFunction(error)) {
            await error(e);
          }
          return defaultValue;
        }
      };
    },
    getData(api) {
      return async () => {
        let res = null;
        try {
          res = await axios.get(api);
          res = res.data;
          return res;
        } catch (e) {
          this.errorMsgBox(e.message);
          if (_.isFunction(error)) {
            await error(e);
          }
          return null;
        }
      };
    },
    apiAll(data, api, config = {}) {
      axios.defaults.baseURL = "https://daimane.com/freeweb";
      // axios.defaults.baseURL =
      //   window.location.origin == "http://localhost:8070"
      //     ? "api"
      //     : "https://daimane.com/freeweb/";

      //1、默认api
      if (!api) {
        api = window.http;
      }
      //2、上传地址时使用心得content-type

      if (api == "/newHuiyiUpload") {
        //文件上传头Content-Type
        axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
      } else {
        //默认头Content-Type
        axios.defaults.headers.post["Content-Type"] =
          "application/x-www-form-urlencoded";
      }
      //3、默认空参数
      if (!data) {
        const params = {};
        data.params = JSON.stringify(params);
      }
      let qsParam = qs.stringify(data);
      return async () => {
        const { method = "POST", error, defaultValue = null } = config;
        let res = null;
        try {
          if (method.toUpperCase() === "GET") {
            res = await axios.get(api, {
              params: qsParam,
            });
          } else {
            res = await axios.post(api, qsParam);
          }
          res = res.data;
          return res;
        } catch (e) {
          this.successMsgBox(e.message);
          if (_.isFunction(error)) {
            await error(e);
          }
          return defaultValue;
        }
      };
    },
    apiAll2(data, api, config = {}) {
      axios.defaults.baseURL =
        window.location.origin == "http://localhost:8070"
          ? "http://localhost:7812/wgnweb/"
          : "https://daimane.com/wgnoweb/";

      //1、默认api
      if (!api) {
        api = window.http;
      }
      //2、上传地址时使用心得content-type

      // if (api == "/newHuiyiUpload") {
      // 	//文件上传头Content-Type
      // 	axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
      // } else {
      // 	//默认头Content-Type
      // 	axios.defaults.headers.post["Content-Type"] =
      // 		"application/x-www-form-urlencoded";
      // }
      //3、默认空参数
      if (!data) {
        const params = {};
        data.params = JSON.stringify(params);
      }
      let qsParam = qs.stringify(data);
      return async () => {
        const { method = "POST", error, defaultValue = null } = config;
        let res = null;
        try {
          if (method.toUpperCase() === "GET") {
            res = await axios.get(api, {
              params: qsParam,
            });
          } else {
            res = await axios.post(api, qsParam);
          }
          res = res.data;
          return res;
        } catch (e) {
          this.successMsgBox(e.message);
          if (_.isFunction(error)) {
            await error(e);
          }
          return defaultValue;
        }
      };
    },
    async withLoading(asyncFunc) {
      let res = null;
      try {
        res = await asyncFunc();
      } catch (e) {
        console.error("WithLoading Error: ", e);
      }
      return res;
    },
  },
});
app.use(router).mount("#app");
