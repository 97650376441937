import "core-js/modules/es.array.push.js";
import TEditor from "./TEditor.vue";
import vueMarkdown from "./vueMarkdown.vue";
export default {
  components: {
    TEditor
  },
  created() {
    this.getInit();
  },
  data() {
    return {
      secondModel: {},
      secondNameDialog: false,
      content: "",
      tableMaxHeight: "",
      course_name: "",
      second_name: "",
      size: "meduim",
      secondNameList: [],
      courseTypeList: [],
      model_simple_cloud_admin_account: {},
      dataList: [],
      page: {
        pageSize: 20,
        startPage: 1,
        totalResult: 0
      },
      accountDialogShow: false,
      currentRow: {},
      queryModel: {},
      indexShow: false,
      currentCourse: {},
      columns: [{
        title: "序号",
        dataIndex: "index",
        key: "index",
        width: "90px"
      }, {
        title: "标题",
        dataIndex: "course_name",
        key: "course_name"
      }, {
        title: "二级标题",
        dataIndex: "second_name",
        key: "second_name"
      }, {
        title: "类型",
        dataIndex: "course_type",
        key: "course_type"
      }, {
        width: "240px",
        title: "操作",
        key: "action"
      }],
      currentType: "",
      currentColumns: [{
        name: "course_name",
        title: "标题",
        isNull: 1
      }, {
        name: "second_name",
        title: "二级标题",
        type: "select-text"
      }, {
        name: "course_type",
        title: "类别",
        type: "radio",
        radiOptions: ["java", "nuxt", "nodejs", "mongoose", "spring", "mysql", "springcloud", "vue", "js", "css", "html", "react", "go", "netty", "docker", "es", "python", "go", "uniapp", "TypeScript"]
      }, {
        name: "course_content",
        type: "TEditor",
        title: "内容"
      }]
    };
  },
  methods: {
    getInit() {
      this.tableMaxHeight = window.innerHeight - 330;

      //初始化
      this.queryModel = {};
      this.getCourseCourseType();
      if (sessionStorage.getItem("current_currentType")) {
        this.currentType = sessionStorage.getItem("current_currentType");
        this.getCourseSecondName();
      }
      if (sessionStorage.getItem("current_second_name")) {
        this.second_name = sessionStorage.getItem("current_second_name");
        this.getCourseBySecondNameForAdmin();
      } else {
        this.getMyAccount();
      }
    },
    gotoWeb(web_addr) {
      window.open(web_addr);
    },
    getContent(content) {
      this.currentRow.course_content = content;
    },
    sizeChange(val) {
      this.page.startPage = 1;
      this.page.pageSize = val;
      this.getMyAccount();
    },
    currentChange(val) {
      this.page.startPage = val;
      this.getMyAccount();
    },
    async getCourseSecondName() {
      const res = await this.withLoading(this.apiAll2({
        course_type: this.currentType
      }, "getCourseSecondName"));
      window.sessionStorage.setItem("current_currentType", this.currentType);
      this.secondNameList = res;
    },
    async getCourseCourseType() {
      const res = await this.withLoading(this.apiAll2({}, "getCourseCourseType"));
      this.courseTypeList = res;
    },
    async deleteWeb(id) {
      let data = {};
      let params = {};
      params.ephemeralParam = id;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/deleteCourse"));
      if (res.code >= 0) {
        this.getMyAccount();
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async getMyAccount() {
      //   let course_name = this.course_name
      //     ? this.course_name
      //     : window.sessionStorage.getItem("current_course_name");

      //   let currentType = this.currentType
      //     ? this.currentType
      //     : window.sessionStorage.getItem("current_currentType");

      const res = await this.withLoading(this.apiAll2({
        page: this.page,
        name: this.course_name,
        course_type: this.currentType
      }, "getCourseForAdmin"));
      //   if (this.course_name) {
      //     window.sessionStorage.setItem("current_course_name", this.course_name);
      //   }
      //   if (this.currentType) {
      //     window.sessionStorage.setItem("current_currentType", this.currentType);
      //   }
      if (res) {
        this.dataList = res.items;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async saveCourseIndex() {
      const res = await this.withLoading(this.apiAll2({
        _id: this.currentCourse._id,
        new_index: this.currentCourse.new_index
      }, "saveCourseIndex"));
      if (res) {
        this.getCourseBySecondNameForAdmin();
        this.indexShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async getCourseBySecondNameForAdmin() {
      const res = await this.withLoading(this.apiAll2({
        page: this.page,
        name: this.second_name
      }, "getCourseBySecondNameForAdmin"));
      window.sessionStorage.setItem("current_second_name", this.second_name);
      if (res) {
        this.dataList = res.items;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    editInterview(row) {
      // this.currentRow = row;
      // this.accountDialogShow = true;
      this.$router.push("/courseWriter/" + row._id);
    },
    showIndex(record) {
      this.currentCourse = record;
      this.indexShow = true;
    },
    gotoCourseWrite() {
      this.$router.push("/courseWriter/0");
    },
    //保存用户信息
    async submit() {
      // //编辑
      // let data = {};
      // let params = {};
      // let list = [];
      // params.ephemeralParam = this.currentRow;
      // data.params = JSON.stringify(params);
      // const res = await this.withLoading(this.apiAll(data, '/saveCourse'));
      // if (res.code >= 0) {
      //     this.getMyAccount();
      //     this.accountDialogShow = false;
      // } else {
      //     this.errorMsgBox(res.message);
      // }

      if (!this.currentRow.course_name) {
        this.errorMsgBox("标题不能为空！");
        return false;
      }
      if (!this.currentRow.course_content) {
        this.errorMsgBox("内容不能为空！");
        return false;
      }
      if (!this.currentRow.course_type) {
        this.errorMsgBox("类别不能为空！");
        return false;
      }
      //编辑
      let data = {};
      let params = {};
      let list = [];
      params.ephemeralParam = this.currentRow;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll2(this.currentRow, "saveCourse"));
      if (res) {
        this.successMsgBox("保存成功！");
        this.accountDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    //批量修改二级标题
    async updateSecondName() {
      const res = await this.withLoading(this.apiAll2(this.secondModel, "updateSecondName"));
      if (res) {
        this.successMsgBox("保存成功！");
        this.secondNameDialog = false;
        this.getMyAccount();
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async deleteInterview(id) {
      //   let data = {};
      //   let params = {};
      //   params.ephemeralParam = id;
      //   data.params = JSON.stringify(params);
      //   const res = await this.withLoading(this.apiAll(data, "/deleteInterview"));
      //   if (res.code >= 0) {
      //     this.successMsgBox(res.message);
      //     this.getInit();
      //   } else {
      //     this.errorMsgBox(res.message);
      //   }
    },
    handleRemove(f, fileList) {
      fileList.some((element, index) => {
        if (element.name == f.name) {
          fileList.splice(index, 1);
        }
      });
    },
    searchData() {},
    clearSearch() {
      this.course_name = "";
      this.second_name = "";
      sessionStorage.removeItem("current_second_name");
      sessionStorage.removeItem("current_currentType");
      this.getMyAccount();
    },
    handleSelectionChange(val) {}
  }
};