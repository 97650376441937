import "core-js/modules/es.array.push.js";
export default {
  created() {
    this.getInit();
  },
  data() {
    return {
      size: "meduim",
      getType: 1,
      tableMaxHeight: "",
      model_simple_cloud_admin_account: {},
      dataList: [],
      page: {
        pageSize: 20,
        startPage: 1,
        totalResult: 0
      },
      accountDialogShow: false,
      fixedNameDialogShow: false,
      currentRow: {},
      editType: "",
      queryModel: {},
      currentColumns: [{
        name: "name",
        title: "名称",
        isNull: 1
      }, {
        name: "type",
        title: "类别",
        type: "radio",
        radiOptions: ["男", "女", "游戏", "宠物", "英文", "鸡汤", "诗词", "公司"]
      }, {
        name: "secondtype",
        title: "二级类别",
        type: "radio",
        radiOptions: ["带符号", "不带"]
      }, {
        name: "name_desc",
        title: "描述",
        type: "textarea"
      }]
    };
  },
  methods: {
    getInit() {
      //初始化
      this.tableMaxHeight = window.innerHeight - 210;
      this.queryModel = {};
      this.getMyAccount();
    },
    sizeChange(val) {
      this.page.startPage = 1;
      this.page.pageSize = val;
      this.getMyAccount();
    },
    currentChange(val) {
      this.page.startPage = val;
      this.getMyAccount();
    },
    async getNameWxlog() {
      let data = {};
      let params = {};
      if (this.queryModel.name) {
        params.ephemeralParam = this.queryModel.name;
      }
      params.page = this.page;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/getNameWxlog"));
      if (res.code >= 0) {
        this.dataList = res.extra;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async saveDayFixedUserName() {
      let data = {};
      let params = {};
      params.ephemeralParam = this.currentRow;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/saveDayFixedUserName"));
      if (res.code >= 0) {
        this.successMsgBox("添加成功！");
        this.fixedNameDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async getDayFixedUserNameForAdmin() {
      let data = {};
      let params = {};
      if (this.queryModel.name) {
        params.ephemeralParam = this.queryModel.name;
      }
      params.page = this.page;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/getDayFixedUserNameForAdmin"));
      if (res.code >= 0) {
        this.dataList = res.extra;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async getMyAccount() {
      let data = {};
      let params = {};
      if (this.queryModel.name) {
        params.ephemeralParam = this.queryModel.name;
      } else if (this.queryModel.type) {
        params.ephemeralParam1 = this.queryModel.type;
      }
      params.page = this.page;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll2(data, "/getName"));
      if (res.code >= 0) {
        this.dataList = res.extra;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    editInterview(row) {
      this.currentRow = row;
      this.accountDialogShow = true;
    },
    async saveName() {
      //编辑
      let data = {};
      let params = {};
      params.ephemeralParam = this.currentRow;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/saveName"));
      if (res.code >= 0) {
        this.getMyAccount();
        this.accountDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    //保存用户信息
    async submit() {
      if (this.currentRow.id) {
        this.saveName();
      } else {
        //编辑
        let data = {};
        let params = {};
        let list = [];
        list.push(this.currentRow);
        params.ephemeralParamList = list;
        data.params = JSON.stringify(params);
        const res = await this.withLoading(this.apiAll(data, "/addName"));
        if (res.code >= 0) {
          this.getMyAccount();
          this.accountDialogShow = false;
        } else {
          this.errorMsgBox(res.message);
        }
      }
    },
    async deleteInterview(id) {
      //   let data = {};
      //   let params = {};
      //   params.ephemeralParam = id;
      //   data.params = JSON.stringify(params);
      //   const res = await this.withLoading(this.apiAll(data, "/deleteInterview"));
      //   if (res.code >= 0) {
      //     this.successMsgBox(res.message);
      //     this.getInit();
      //   } else {
      //     this.errorMsgBox(res.message);
      //   }
    },
    handleRemove(f, fileList) {
      fileList.some((element, index) => {
        if (element.name == f.name) {
          fileList.splice(index, 1);
        }
      });
    },
    openEdit() {
      this.currentRow = {};
      this.accountDialogShow = true;
    },
    openEdit1() {
      this.currentRow = {};
      this.fixedNameDialogShow = true;
    },
    searchData() {
      alert("这里是搜索！");
    },
    clearSearch() {
      alert("清除所有搜索条件！");
    }
  }
};